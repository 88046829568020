<template>
  <fragment>
    <div class="page-container">
      <a-row class="oa-list" :gutter="[20, 20]">
        <a-col :xxl="8" :span="12" v-for="(item, index) in list" :key="index">
          <div class="oa-module">
            <div class="oa-module-hd">
              <div class="oa-module-title">
                <span class="oa-module-title-icon">
                  <app-icon type="icon-xinwen"></app-icon>
                </span>
                <span class="oa-module-title-text">{{ item.title }}</span>
              </div>
              <div class="oa-module-actions">
                <a class="action-add" :href="generateSsoUrl(item.aUrl)" target="_blank">
                  <a-icon type="plus-circle" v-if="index + 1 !== list.length"></a-icon>
                </a>
                <a class="action-more ml-2" @click="open(item.tUrl)">More ></a>
              </div>
            </div>
            <div class="oa-module-bd">
              <div class="oa-module-article-list" v-if="item.nodes.length">
                <div class="oa-module-article-item" v-for="(child, cIndex) in item.nodes.slice(0, 5)" :key="cIndex">
                  <a @click="open(child.id)">
                    {{ child.title }}
                  </a>
                </div>
              </div>
              <Empty v-else descripiton="暂无数据" class="-mt-5"></Empty>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <ToolsSearch @search="search"></ToolsSearch>
  </fragment>
</template>

<script>
import Empty from '@/components/Empty'
import ToolsSearch from '@/components/ToolsSearch'
import { open, generateSsoUrl } from '@/service/navigate'
export default {
  components: {
    Empty,
    ToolsSearch,
  },
  data() {
    return {
      list: [],
      searchUrl: '',
    }
  },
  mounted() {
    this.loadOaModules()
  },
  methods: {
    search(keywords) {
      // ie11有window.btoa会报错。。。找不到原因，
      const expression = 'window.btoa'
      // eslint-disable-next-line
      const base64url = eval(expression)(
        `/spa/esearch/static/index.html#/main/esearch/result?searchType=CONTENT&type=ALL&value=${encodeURI(keywords)}`
      )
      const targetUrl = `${this.searchUrl}?url=${base64url}`
      open(targetUrl)
    },
    open,
    generateSsoUrl,
    async loadOaModules() {
      const res = await this.$axios.post('/oaext/unify/getUnifyInfos')
      const { aList, db, searchUrl } = res.data

      this.list = [...aList, db]
      this.searchUrl = searchUrl
    },
  },
}
</script>

<style lang="less" scoped>
.page-container {
  width: 100%;
  position: absolute;
  overflow: auto;
  top: 100px;
  bottom: 0;
  left: 0;
  padding: 0px 30px 30px;
}

.oa-list {
  padding-bottom: 0;

  .oa-module {
    height: 238px;
    background: #ffffff;
    box-shadow: 0px 6px 10px 0px rgba(99, 155, 252, 0.05);
    border-radius: 4px;
    .oa-module-hd {
      height: 52px;
      line-height: 52px;

      border-bottom: 1px solid #e7e7e7;
      padding: 0 15px;
      display: flex;
      position: relative;
      .oa-module-title {
        width: calc(100% - 120px);
        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .oa-module-title-icon {
          width: 16px;
          height: 16px;
          font-size: 16px;
          color: #639bfc;
        }
        .oa-module-title-text {
          color: #30425b;
          font-size: 16px;
          margin-left: 10px;
          width: 100%;
        }
      }
      .oa-module-actions {
        position: absolute;
        right: 15px;
        display: flex;
        align-items: center;
        bottom: 0;
        top: 0;
        margin: auto;
        .action-more {
          color: #639bfc;
        }
        .action-add {
          color: #639bfc;
        }
      }
    }
    .oa-module-bd {
      padding: 15px 15px 0;
      height: 186px;
      .oa-module-article-item {
        margin-bottom: 13px;
        position: relative;
        padding: 0 18px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
          color: #a0afc7;
        }
        &:hover {
          a {
            color: #639bfc;
          }
          &:before {
            background: #639bfc;
            border: 0;
          }
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          border-radius: 50%;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 8px;
          height: 8px;
          border: 2px solid #a0afc7;
        }
      }
    }
  }
}
</style>
