<template>
  <div class="content-empty">
    <img v-if="image === 'default'" class="empty-image" src="./default.png" />
    <auto-img class="empty-image" v-else :src="image"></auto-img>
    <div class="empty-desc">{{ descripiton }}</div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: 'default',
    },
    descripiton: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" scoped>
.content-empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty-image {
    max-width: 50%;
    max-height: 50%;
  }
  .empty-desc {
    margin-left: 15px;
    color: #a0afc7;
  }
}
</style>
